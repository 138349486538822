import React, { useState, useEffect } from "react";
import useEventListener from "@use-it/event-listener";
import axios from "../../../axios";
import sugar from "sugar";
import AlertDialog from "../../../components/UI/Alert/Alert";
import classes from "./CustomerAddStock.css";
import Card from "../../../components/UI/Card/Card";
import SizedBox from "../../../components/UI/SizedBox/SizedBox";
import LoadingSpinner from "../../../components/UI/LoadingSpinner/LoadingSpinner";
import SaveButton from "../../../components/UI/Buttons/SaveButton/SaveButton";
import Switch from "react-switch";
import MUIDataTable from "mui-datatables";
import TableSetting from "../../../tableSettings";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";

const CustomerAddStock = (props) => {
    const [contactData, setContactdata] = useState([]);
    const [data, setData] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [isAlert, setIsAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState("");
    const [alertContent, setAlertContent] = useState("");
    const [isContent, setIsContent] = useState(false);
    const [isAdd, setIsAdd] = useState(true);
    var typedText = "";
    const [billNumber, setBillNumber] = useState();
    const id = props.history.location.pathname.split("/")[2];

    const handleLoadingSpinner = (loading) => {
        setLoading(loading);
    };
    const handleSwitchChange = (isAdd) => {
        setIsAdd(!isAdd);
    };
    const handleAddButton = () => {
        setIsAdd(true);
    };
    const handleRemoveButton = () => {
        setIsAdd(false);
    };

    const handleRemoveItem = (value) => {
        const updatedData = [...data];
        sugar.Array.remove(updatedData, function(el) {
            return el.QR === value;
        });
        setData(updatedData);
    };

    const handleAddItem = (value) => {
        const sub = (st, en) => value.substring(st, en);
        var unique = true;
        data.forEach((el) => {
            if (value === el.QR) {
                unique = false;
            }
        });
        if (!unique) {
            return;
        }
        const GTIN = sub(2, 15);
        const SN = sub(34, 39);
        const jetzt = new Date();

        const handleAddItem = () => {
            const extracted = {
                QR: value,
                GTIN: GTIN,
                LOT: sub(17, 24),
                expDate: ["20" + sub(26, 28), sub(28, 30), sub(30, 32)].join("-"),
                SN: SN,
            };
            const updatedData = [...data];
            updatedData.push(extracted);
            setData(updatedData);
        };
        handleAddItem();
    };

    const handleAddItem2 = (value) => {
        const sub = (st, en) => value.substring(st, en);
        var unique = true;
        data.forEach((el) => {
            if (value === el.QR) {
                unique = false;
            }
        });
        if (!unique) {
            return;
        }
        const GTIN = sub(3, 16);
        const SN = sub(35, 50);
        const jetzt = new Date();

        const handleAddItem = () => {
            const extracted = {
                QR: value,
                GTIN: GTIN,
                LOT: sub(18, 25),
                expDate: ["20" + sub(27, 29), sub(29, 31), sub(31, 33)].join("-"),
                SN: SN,
            };
            const updatedData = [...data];
            updatedData.push(extracted);
            setData(updatedData);
        };
        handleAddItem();
    };


    const handleAddItem3 = (value) => {
        const sub = (st, en) => value.substring(st, en);
        var unique = true;
        data.forEach((el) => {
            if (value === el.QR) {
                unique = false;
            }
        });
        if (!unique) {
            return;
        }
        const GTIN = sub(3, 16);
        const SN = sub(34, 49);
        const jetzt = new Date();

        const handleAddItem = () => {
            const extracted = {
                QR: value,
                GTIN: GTIN,
                LOT: sub(18, 24),
                expDate: ["20" + sub(26, 28), sub(28, 30), sub(30, 32)].join("-"),
                SN: SN,
            };
            console.log("extracted", extracted)

            const updatedData = [...data];
            updatedData.push(extracted);
            setData(updatedData);

        };
        handleAddItem();

    };

    const handleSave = (event) => {
        event.preventDefault();
        const billedData = data.map((item) => {
            item["billNumber"] = billNumber;
            return item;
        });
        handleLoadingSpinner(true);
        axios
            .post(`/customer/:${id}/stockadd`, billedData)
            .then((result, error) => {
                handleLoadingSpinner(false);
                if (error) {
                    return;
                }
                console.log(result);
                setAlertTitle("Items added succefully to the customer's stock.");
                setIsContent(false);
                setData([]);
                setIsAlert(true);
            })
            .catch((err) => {
                setAlertTitle("Error. something went wrong");
                setIsContent(false);
                setIsAlert(true);
            });
    };

    const handleBillNumberInput = async(event) => {
        var subString = event.target.value.replace("105308", "");
        setBillNumber(subString);
        /* if (event.target.value.length == 15) {
          var subString = event.target.value.substring(0, 6);
          if (subString == "105308") {
            var billId = event.target.value.substring(10, 15);
            setBillNumber(billId);
          }
        } */

        if (event.target.value.length >= 15) {
            var result = await axios.get(
                `/invoices/invoice?invoiceId=${event.target.value}`
            );
            var data = result.data.result;
            if (data) {
                var billNumber = data.invoiceNumber;
                setBillNumber(billNumber);
            }
        }
        /*
         */
    };

    const handleKeypress = (event) => {
        typedText = typedText.replace("Shift", "");
        typedText = typedText.replace("Alt00029", "");
        console.log(typedText)

        // if enter pressed
        if (event.keyCode === 13 && typedText.length >= 39) {
            console.log("lets see")
            console.log(typedText)

            //010803363895757810A02538172503312103000023541ZRMK

            var longString = typedText.substring(typedText.length - 39);



            if (longString.substring(0, 5) == "50951" || longString.substring(0, 5) == "57578") {
                // this is the version 2 qr code
                longString = typedText.substring(typedText.length - 50);

                var GTIN = longString.substring(3, 16);

                if (GTIN === "8033638950951" || GTIN === "8033638957578") {
                    isAdd ? handleAddItem2(longString) : handleRemoveItem(longString);
                }
            }
            if (longString.substring(1, 6) == "50951" || longString.substring(1, 6) == "57578") {
                // this is the version 3 qr code
                console.log("this is version 3")
                longString = typedText.substring(typedText.length - 49);
                var GTIN = longString.substring(3, 16);

                if (GTIN === "8033638950951" || GTIN === "8033638957578") {
                    isAdd ? handleAddItem3(longString) : handleRemoveItem(longString);
                }

            } else {
                var GTIN = longString.substring(2, 15);
                if (GTIN === "8033638950951" || GTIN === "8033638957578") {
                    isAdd ? handleAddItem(longString) : handleRemoveItem(longString);
                }
            }

            typedText = "";
            return;
        }
        typedText = typedText + event.key;
    };

    useEventListener("keydown", handleKeypress);
    useEffect(() => {
        axios.get(`/customer/${id}`).then((res) => setContactdata(res.data));
    }, []);

    var Alert = () => {
        return null;
    };
    if (isAlert) {
        Alert = () => {
            return ( <
                AlertDialog close = {
                    () => setIsAlert(false)
                }
                title = { alertTitle }
                content = { alertContent }
                isContent = { isContent }
                />
            );
        };
    }

    return ( <
        div className = { classes.AddStock } >
        <
        h1 >
        Add items to { contactData.name ? `${contactData.name}'s` : "....." } { " " }
        stock <
        /h1> <
        h2 > تأكد من تحويل لوحة المفاتيح إلى اللغة الانجليزية < /h2> <
        div className = { classes.Cards } >
        <
        div onClick = { handleAddButton } >
        <
        Card backgroundColor = { "#01ACC1" }
        icon = { "Add" }
        size = "medium" / >
        <
        /div> <
        SizedBox width = { 10 }
        /> <
        Switch height = { 28 }
        width = { 56 }
        offColor = "#01ACC1"
        onColor = "#C10134"
        uncheckedIcon = { false }
        checkedIcon = { false }
        onChange = { handleSwitchChange }
        checked = {!isAdd }
        /> <
        SizedBox width = { 10 }
        /> <
        div onClick = { handleRemoveButton } >
        <
        Card backgroundColor = { "#C10134" }
        icon = { "Remove" }
        size = "medium" / >
        <
        /div> < /
        div > <
        br / >
        <
        MUIDataTable title = { "Items to add" }
        data = { data }
        columns = { TableSetting.columns }
        options = { TableSetting.options }
        className = { classes.Table }
        /> <
        Paper className = { classes.Paper } >
        <
        TextField required id = "standard-required"
        label = "Bill Number"
        margin = "normal"
        className = { classes.SubTextField }
        onChange = { handleBillNumberInput }
        value = { billNumber }
        type = "number" /
        >
        <
        /Paper> <
        form onSubmit = { handleSave } >
        <
        br / >
        <
        div className = { classes.Button } >
        <
        button onClick = {
            () => {}
        } >
        <
        SaveButton text = "Save"
        // click={handleSave}
        icon = "saveIcon"
        backgroundColor = "#01ACC1"
        color = "#ffffff"
        width = { 200 }
        height = { 50 }
        fontSize = { 25 }
        hoverBackground = "#02C6DE" /
        >
        <
        /button> < /
        div > <
        /form> <
        Alert / >
        <
        LoadingSpinner isLoading = { isLoading }
        /> < /
        div >
    );
};
export default CustomerAddStock;